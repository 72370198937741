<template lang='pug'>
head-meta(
  title-page="О Музее «Шоколадный Выборг»",
)
the-about
contacts-line
</template>

<script>

import TheAbout from '../components/about/TheAbout'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'
export default {
  name: 'AboutPage',
  components: { ContactsLine, HeadMeta, TheAbout }
}
</script>
