<template lang='pug'>
about-cap
index-slide
article.about
  .about__cont.cont
    p.about__text
      | Музей «Шоколадный Выборг» расположен в интереснейшем историческом здании – пороховом погребе.
    p.about__text
      | Российская императрица Анна Иоановна приказала построить укрепления с западной стороны города, как велел в своё
      | время Пётр Первый. Они так и назывались — Кронверк Святой Анны в честь святой покровительницы императрицы. И
      | пороховой погреб входил в систему обороны города XVIII века.
    p.about__text
      | Сейчас это памятник истории и культуры федерального значения, источник притяжения для туристов, гостей города,
      | отличное место отдыха и проведения досуга для всей семьи.
      | Благодаря бережному отношению и сохранению исторического облика здания порохового погреба частными инвесторами,
      | сегодня каждый может почувствовать дух той эпохи и оказаться в атмосфере сказки, которая привлекает и детей, и взрослых!
    h2.about__title.title Почему музей, а не просто магазин
    p.about__text Потому что у нас вы сможете:
    ul.about__list
      li.about__item
        | посетить увлекательную экскурсию, на которой хранительница музея расскажет историю появления шоколада и его
        | преображения, интересные факты, легенды и многое другое
      li.about__item
        | продегустировать непревзойденный бельгийский шоколад за чашкой ароматного чая, кофе или горячего шоколада
      li.about__item
        | почувствовать себя настоящими шоколатье на мастер-классе по шоколадному искусству для взрослых и детей
      li.about__item
        | приобрести шоколадные изделия ручной работы, созданные нашими шоколатье по авторским рецептам прямо в стенах музея!
    .about__nav
      router-link.about__nav-link.about__nav-link_catalog(:to="{ name: 'Catalog'}")
        span.about__nav-text.link-more Смотреть каталог
      router-link.about__nav-link.about__nav-link_excursion(:to="{ name: 'Excursion'}")
        span.about__nav-text.link-more Подробнее о мероприятиях
      router-link.about__nav-link.about__nav-link_order(:to="{ name: 'OnOrder'}")
        span.about__nav-text.link-more Шоколад под заказ
      router-link.about__nav-link.about__nav-link_grot(:to="{ name: 'Grot'}")
        span.about__nav-text.link-more Грот — место силы
    h2.about__title.title Открытие Музея «Шоколадный Выборг»
    .about__gallery
      photo-gallery(:images="images")
home-video
</template>

<script>
import '@/assets/styles/components/about.sass'
import AboutCap from './AboutCap'
import IndexSlide from '../IndexSlide'
import HomeVideo from '../HomeVideo'
import PhotoGallery from '../PhotoGallery'

export default {
  name: 'TheAbout',
  components: { PhotoGallery, HomeVideo, IndexSlide, AboutCap },
  data () {
    return {
      images: [
        { path: '/images/photo-gallery/photo-gallery-7', alt: 'Открытие Музея «Шоколадный Выборг» фото', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-8', alt: 'Официальное открытие Музея «Шоколадный Выборг» фото', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-9', alt: 'Дегустация шоколада на открытии Музея «Шоколадный Выборг» фото', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-10', alt: 'Фото с Петром I фото', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-11', alt: 'Анимация для детей на открытии Музея «Шоколадный Выборг» фото', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-12', alt: 'Развлечения для детей в Музее «Шоколадный Выборг» фото', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-13', alt: 'Торжественное открытие Музея «Шоколадный Выборг» фото', mod: 'big' }
      ]
    }
  }
}
</script>
