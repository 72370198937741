<template lang='pug'>
aside.about-cap
  .about-cap__cont.cont
    h1.about-cap__caption.caption О Музее «Шоколадный Выборг»
    p.about-cap__text
      | Музей «Шоколадный Выборг» — это уникальное пространство, распахнувшее свои двери
      |
      span.about-cap__span 30 ноября 2019 года!
    p.about-cap__text
      | Территория, соединившая в себе музей с экспонатами выборгских достопримечательностей из настоящего бельгийского
      | шоколада, магазин шоколада с собственным производством и уютное кафе, в котором гостям предложат чай, кофе и
      | наивкуснейший горячий шоколад!
</template>

<script>
import '@/assets/styles/components/about-cap.sass'

export default {
  name: 'AboutCap'
}
</script>
